import revive_payload_client_5WXFQJdIK8 from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_j9vp0wge1M from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DgQIX0Dfle from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qdcIQUsfGB from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_v9VJlwIVUa from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_M0M7FtYOC6 from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_RhjolIJUb8 from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_uF9IyVaAdl from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_GVzNqFM1Kc from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.23.0_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_I0siso67vE from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.23.0_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_vvCFqbjK5D from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.23.0_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client_JOOstaeYmp from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/apps/kinoheld/.nuxt/formkitPlugin.mjs";
import plugin_WXacR3Rf8e from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.2.3_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_7YYoakauub from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@types+node@22.10.1_graphql-tag@2.12.6_typescript@5.8.3/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/layers/base/plugins/sentry.client.ts";
import widget_mode_X6BjB4n2GO from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/layers/base/plugins/widget-mode.ts";
import google_publisher_tag_9jDGgApxI5 from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/layers/portal/plugins/google-publisher-tag.ts";
import ssg_detect_zwwqiLh0we from "/home/forge/deployments/kinoheld/live/2025-04-08_14-07-59_3f1517d/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.23.0_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_5WXFQJdIK8,
  unhead_j9vp0wge1M,
  router_DgQIX0Dfle,
  payload_client_qdcIQUsfGB,
  navigation_repaint_client_v9VJlwIVUa,
  check_outdated_build_client_M0M7FtYOC6,
  view_transitions_client_RhjolIJUb8,
  chunk_reload_client_uF9IyVaAdl,
  switch_locale_path_ssr_GVzNqFM1Kc,
  route_locale_detect_I0siso67vE,
  i18n_vvCFqbjK5D,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JOOstaeYmp,
  formkitPlugin_pZqjah0RUG,
  plugin_WXacR3Rf8e,
  plugin_7YYoakauub,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV,
  widget_mode_X6BjB4n2GO,
  google_publisher_tag_9jDGgApxI5,
  ssg_detect_zwwqiLh0we
]